$(function () {
    $(document).on('click', '.jsGoToForm', function () {
        var scrollEl = $(this).attr('data-form');
        if ($(scrollEl).length > 0) {
            $('html, body').animate({scrollTop: $(scrollEl).offset().top}, 700);
        }
        return false;
    });
    $('.jsValidate').validate({
        rules: {
            phone: {
                minlength: 6
            }
        },
        messages: {
            phone: {
                required: 'Пожалуйста, введите номер телефона.',
                minlength: 'Пожалуйства, введите корректный номер телефона.'
            },
            personal: {
                required: 'Пожалуйста, примите соглашение на обработку персональных данных.'
            },
            email: {
                email: 'Пожалуйста, введите корректный Email.',
            }
        },
        errorElement: 'span',
        errorClass: 'error-label',
        errorPlacement: function (error, element) {
            var $parent = $(element).parent();
            $parent.append(error);
            $(error).addClass('small text-danger');
            $(element).next('span').addClass('error');
        },
        unhighlight: function (element) {
            $(element).next('span').removeClass('error').addClass('ok');
            $(element).parent().find('span.error-label').remove();
        },
        submitHandler: function (form) {
            $('.for-result').find('.alert').remove();
            var formData = new FormData(form);
            $.ajax({
                type: "POST",
                url: "/send.php",
                data: formData,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    if (data.status) {
                        form.reset();
                        $('.for-result').append('<div class="alert alert-success text-center mt-3">Спасибо за ваш запрос. Менеджер ответит на него в течение 2 рабочих дней.</div>');
                    } else {
                        $('.for-result').append('<div class="alert alert-danger text-center mt-3">Ошибка! Проверьте правильность заполненных полей.</div>');
                    }
                }
            });
        }
    });
});
